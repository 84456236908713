<template>
  <div>
    <div class="bg_dark"></div>
    <div class="modal">
      <div class="modal-left">
        <img src="@/assets/img/left-banner.webp" />
        <p class="mobile">СЫГРАЕМ!</p>
      </div>
      <div class="modal-right">
        <button class="modal-close">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.1894 6L6.35156 18"
              stroke="gray"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M6.35156 6L18.1894 18"
              stroke="gray"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
        </button>
        <div class="modal-right-content">
          <h2 class="title">Регистрация</h2>
          <form
            action="#"
            id="registrationForm"
            :class="{'form_disabled': isRequestSending}"
            @submit.prevent="submitForm"
          >
            <div class="form-row">
              <label for="telCode">Телефон</label>
              <select id="telCode" name="telCode" v-model="telCode">
                <option value="+7" selected>+7</option>
                <option value="+380">+380</option>
              </select>
              <input type="tel" id="phone" placeholder="Номер телефона" required v-model="phone" />
            </div>
            <div class="form-group">
              <label for="password"></label>
              <input
                type="password"
                id="password"
                name="password"
                placeholder="Введите пароль"
                required
                v-model="password"
              />
              <span class="showpassword">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="svg-716e1d">
                    <path
                      id="svg-12d1a8"
                      d="M2 12C2 12 5 5 12 5C19 5 22 12 22 12C22 12 19 19 12 19C5 19 2 12 2 12Z"
                      stroke="#a9bcf0"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      id="svg-e962c"
                      d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                      stroke="#a9bcf0"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </g>
                </svg>
              </span>
            </div>
            <div class="form-row">
              <label for="currency">Валюта</label>
              <select id="currency" name="currency" v-model="currency">
                <option value="RUB" selected>RUB</option>
                <option value="UAH">UAH</option>
              </select>
              <label for="country" id="labelcountry">Страна</label>
              <select id="country" v-model="country">
                <option value="RU" selected>Россия</option>
                <option value="UA">Украина</option>
              </select>
            </div>
            <button type="button" class="div_text" id="checkCode" @click.prevent="showPromocode = !showPromocode">
              Есть промокод?
              <span
              ><svg
                width="10"
                height="7"
                viewBox="0 0 10 7"
                fill="#2f2fde"
                xmlns="http://www.w3.org/2000/svg"
              >
                  <path
                    d="M5.77466e-08 1.32109C7.26125e-08 1.66118 0.121736 2.00769 0.377386 2.26437L4.28762 6.26723C4.68013 6.66904 5.3265 6.66883 5.71875 6.26676L9.62337 2.26437C10.1225 1.75102 10.1286 0.9104 9.62337 0.390634C9.13033 -0.122716 8.3147 -0.129133 7.81557 0.390634L4.99733 3.2718L2.18518 0.384211C1.67997 -0.129138 0.870419 -0.129132 0.371293 0.390634C0.127816 0.647309 4.31611e-08 0.987411 5.77466e-08 1.32109Z"
                  ></path>
                </svg>
              </span>
            </button>
            <div v-if="showPromocode" class="form-group codeBlock">
              <input type="text" id="promo" v-model="promocode" />
            </div>
            <div class="checkbox-group">
              <input type="checkbox" checked id="checkbox" />
              <label for="checkbox"
              >Я согласен(-на) с <a href="#">Условиями и приложениями</a> и
                подтверждаю, что мне исполнилось 18 лет</label
              >
            </div>
            <input
              type="submit"
              class="btn-register"
              id="registr"
              value="Регистрация"
            />
          </form>
          <div class="sign-in">
            <p>Есть аккаунт?</p>
            <b
            >Войти
              <span
              ><svg
                width="10"
                height="7"
                viewBox="0 0 10 7"
                fill="#2f2fde"
                xmlns="http://www.w3.org/2000/svg"
              >
                  <path
                    d="M5.77466e-08 1.32109C7.26125e-08 1.66118 0.121736 2.00769 0.377386 2.26437L4.28762 6.26723C4.68013 6.66904 5.3265 6.66883 5.71875 6.26676L9.62337 2.26437C10.1225 1.75102 10.1286 0.9104 9.62337 0.390634C9.13033 -0.122716 8.3147 -0.129133 7.81557 0.390634L4.99733 3.2718L2.18518 0.384211C1.67997 -0.129138 0.870419 -0.129132 0.371293 0.390634C0.127816 0.647309 4.31611e-08 0.987411 5.77466e-08 1.32109Z"
                  ></path></svg></span
              ></b>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification'
import axios from 'axios'

export default {
  name: 'landing-page',
  data () {
    return {
      telCode: '+7',
      phone: '',
      password: '',
      country: 'RU',
      currency: 'RUB',
      promocode: 'SKUF',
      checkbox: false,
      isRequestSending: false,
      showPromocode: false
    }
  },
  methods: {
    submitForm () {
      if (this.isRequestSending) {
        return
      }

      this.isRequestSending = true

      const data = {
        user: {
          country: this.country,
          currency: this.currency,

          bonus_code: this.promocode,
          captcha: '',
          parent_refcode: '',
          profile_attributes: {
            age_acceptance: true,
            terms_acceptance: true,
            game_currency: this.currency,
            receive_newsletters: false,
            receive_promos: false,
            last_name: '',
            first_name: '',
            receive_sms_promos: false,
            nickname: '',
            currency: this.currency,
            language: 'ru',
            country: this.country
          },
          phone: {
            country: this.country,
            number: `${this.telCode}${this.phone}`
          },
          password: this.password,
          password_confirmation: this.password
        },
        lang: 'ru'
      }

      console.log(data)

      this.fetchData(data)
    },
    fetchData (data) {
      axios.post('/script.php', data, {
        headers: { 'Content-Type': 'application/json;charset=utf-8' }
      })
        .then((response) => {
          this.isRequestSending = false

          if (response.status === 200 || response.statusText === 'OK') {
            if (response.data) {
              const { token, url } = response.data

              if (token && token.length) {
                useToast().success('Успешная регистрация! Перенаправление...')
                document.location.href = url

                return
              }
            }

            useToast().error('Ошибка при регистрации')

            return
          }

          console.log('error', response)
          useToast().error('Ошибка при отправке запроса')
        })
        .catch((error) => {
          this.isRequestSending = false
          console.log('request error', error)
          useToast().error('Ошибка при отправке запроса')
        })
    }
  }
}
</script>

<style src="./style.scss" lang="scss"></style>
